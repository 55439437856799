import React from "react";
import { Space, Input, Upload } from "antd";
import PropTypes from "prop-types";
import { UploadOutlined, MinusCircleOutlined } from "@ant-design/icons";
//import { url } from "../../../../api";

export default function TimelineMonth({
  Form,
  initialValue,
  field,
  remove,
  timeline,
  setTimeline,
}) {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const uploadButton = (
    <div>
      {<UploadOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  if (Form === undefined) return <></>;
  else
    return (
      <>
        <Space
          key={field?.key}
          style={{ display: "flex", marginBottom: 8, width: "100%" }}
          align="start"
        >
          <Form.Item
            {...field}
            initialValue={initialValue?.mes}
            name={[field?.name, "mes"]}
            fieldKey={[field?.fieldKey, "mes"]}
            rules={[{ required: true, message: "Adicione um mês" }]}
          >
            <Input placeholder="Mês" />
          </Form.Item>
          <Form.Item
            initialValue={initialValue?.fotos?.map((g) => {
              return {
                uid: g.id,
                name: g.name + g.ext,
                status: "done",
                url: `${g.url}`,
              };
            })}
            name={[field?.name, "fotos"]}
            fieldKey={[field?.fieldKey, "fotos"]}
            getValueFromEvent={normFile}
            valuePropName="fileList"
          >
            <Upload name="fotos" listType="picture-card">
              {uploadButton}
            </Upload>
          </Form.Item>

          <MinusCircleOutlined
            onClick={() => {
              remove(field?.name);
              setTimeline(
                timeline.filter((p) => p.name !== timeline.length - 1)
              );
            }}
          />
        </Space>
      </>
    );
}

TimelineMonth.propTypes = {
  Form: PropTypes.any,
  initialValue: PropTypes.any,
  remove: PropTypes.func,
  field: PropTypes.any,
  timeline: PropTypes.array,
  setTimeline: PropTypes.func,
};
